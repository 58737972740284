.plotCardWrapper :global(.chakra-card__header) {
  padding-bottom: 10px;
}

.plotCard :global([class^='plot-card_topRight']) {
  margin-right: -10px;
}

.plotCard :global(.chakra-card__body:has([class^='line-chart_container'])) {
  margin-top: 5px;
}

.plotCardSmall :global([class^='plot-loader_noDataImage']) {
  display: none;
}

.plotCard :global(#plot-card-top-right) {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.plotCard:hover :global(#plot-card-top-right) {
  opacity: 1;
}

.plotCardOpen :global(#plot-card-top-right) {
  opacity: 1;
}
