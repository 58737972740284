.container {
  width: 100%;
  height: 100%;
  position: relative;
}

.bar {
  cursor: pointer;
  stroke-width: 2px;
  stroke: transparent;
  stroke-opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.barGroup {
  transition: opacity 0.2s ease-in-out;
}

.barGroup.dimmed {
  opacity: 0.3;
}

.barGroup:hover {
  opacity: 1;
}

.bar:hover {
  opacity: 0.8;
}
