@font-face {
  font-family: 'Roboto Medium';
  src:
    local('Roboto Medium'),
    local('Roboto-Medium'),
    url('./fonts/roboto-medium.woff2') format('woff2'),
    url('./fonts/roboto-medium.woff') format('woff');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('./fonts/roboto-v27-latin-regular.woff2') format('woff2'),
    url('./fonts/roboto-v27-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src:
    local(''),
    url('./fonts/roboto-v27-latin-500.woff2') format('woff2'),
    url('./fonts/roboto-v27-latin-500.woff') format('woff');
}
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src:
    local(''),
    url('./fonts/roboto-v27-latin-700.woff2') format('woff2'),
    url('./fonts/roboto-v27-latin-700.woff') format('woff');
}
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 400;
  src:
    local(''),
    url('./fonts/roboto-mono-v13-latin-regular.woff2') format('woff2'),
    url('./fonts/roboto-mono-v13-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Roboto Mono';
  font-style: normal;
  font-weight: 600;
  src:
    local(''),
    url('./fonts/roboto-mono-v13-latin-600.woff2') format('woff2'),
    url('./fonts/roboto-mono-v13-latin-600.woff') format('woff');
}
