.container {
  font-family: 'Source Sans Pro', sans-serif;
  padding: 0;
}

.title {
  font-size: 11px;
  font-weight: 600;
  margin-bottom: 10px;
}

.rows {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.row {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  font-size: 10px;
  color: #d7dbe3;
  font-weight: 400;
}
