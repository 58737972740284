.container {
  width: 100%;
  height: 100%;
}

.path {
  stroke: #5ca9e9;
  transition: stroke 0.1s ease-in-out;
}

.chart:has(.path:hover) .path {
  stroke: #aed4f4;
}

.chart:has(.path:hover) .path:hover {
  stroke: #5ca9e9;
}

.path:hover,
.node:hover {
  cursor: pointer;
}

.tooltipContent {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 12px;
}
