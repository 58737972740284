.tooltip {
  position: absolute;
  transform: translate(16px, -90%);
  min-width: 200px;
  padding: 12px;
  border-radius: 4px;
  background-color: #2d426a;
  color: white;
  box-shadow: 4px 4px 5px #00000020;
  z-index: 10;
  pointer-events: none;
}

.tooltip:before {
  position: absolute;
  top: 80%;
  left: -8px;
  transform: translateY(-50%);
  border-right: 8px solid #2d426a;
  border-bottom: 6px solid transparent;
  border-top: 6px solid transparent;
  content: '';
}

.tooltipLeft {
  transform: translate(calc(-100% - 16px), -50%);
}

.tooltipLeft:before {
  left: unset;
  right: -8px;
  border-right: unset;
  border-left: 8px solid #2d426a;
}

.tooltipHoverLine {
  stroke: black;
  stroke-opacity: 0.2;
  stroke-width: 1.5;
  stroke-dasharray: 3;
}
