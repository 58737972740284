.react-datepicker {
  font-family:
    'Source Sans Pro',
    'Roboto',
    'Helvetica Neue',
    -apple-system,
    system-ui,
    sans-serif;
  overflow: hidden;
  min-width: 219px;
  display: flex;
}
.react-datepicker__navigation--next--with-time:not(
    .react-datepicker__navigation--next--with-today-button
  ) {
  right: 90px;
}
.react-datepicker__navigation--previous,
.react-datepicker__navigation--next {
  height: 30px;
  top: 8px;
}
.react-datepicker__navigation--previous {
  border-right-color: #cbd5e0;
}
.react-datepicker__navigation--previous:hover {
  border-right-color: #a0aec0;
}
.react-datepicker__navigation--next {
  border-left-color: #cbd5e0;
}
.react-datepicker__navigation--next:hover {
  border-left-color: #a0aec0;
}
.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block;
}
.react-datepicker__header {
  border-radius: 0;
  background: #f7fafc;
}
.react-datepicker,
.react-datepicker__header,
.react-datepicker__time-container {
  border-color: #e2e8f0;
}
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-size: inherit;
  font-weight: 600;
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  margin: 0 1px 0 0;
  height: auto;
  padding: 7px 10px;
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item:hover {
  background: #edf2f7;
}
.react-datepicker__day:hover {
  background: #edf2f7;
}

.react-datepicker__month-text--keyboard-selected {
  background-color: #3dc679;
}

.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover {
  background-color: #ebeef3;
}
