.plotLoaderContainer {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffffbb;
  z-index: 10;
}

.plotLoaderImage {
  width: 85px;
}

.noDataContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.noDataImage {
  width: 50%;
  height: auto;
  max-height: 60%;
  max-width: 100%;
  margin-bottom: 12px;
}

.noDataText {
  padding: 0 12px;
  font-size: 16px;
  font-weight: 500;
  color: #667085;
  text-align: center;
}

.smallNoData .noDataText {
  font-size: 12px;
}
