.container {
  position: relative;
  width: 100%;
  height: 100%;
}

.container svg {
  overflow: visible;
}

.chart {
  cursor: pointer;
}

.area-line-stroke {
  stroke: none;
  stroke-width: 0;
  fill-opacity: 0.2;
}

.line {
  stroke-width: 2px;
  fill: none;
}

.xAxisLabel {
  font-size: 11px;
  text-anchor: middle;
  font-family: 'Source Sans Pro', sans-serif;
  fill: #6b7a96;
}

.xAxisLabel.firstTick {
  text-anchor: start;
}
