.container {
  width: 100%;
  height: 100%;
}

.titleContainer {
  display: flex;
  align-items: center;
  width: 100%;
}

.title {
  max-width: calc(100% - 16px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  color: #2d426a;
  font-weight: 600;
  white-space: nowrap;
}

.info {
  margin-left: 4px;
  cursor: pointer;
  line-height: 16px;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.titleContainer:hover .info {
  opacity: 1;
}

.chartContainer {
  display: flex;
  width: 100%;
  height: calc(100% - 21px);
}

.labelContainer {
  pointer-events: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  z-index: 1;
}

.plotContainer {
  width: 50%;
  height: 100%;
}

.value {
  font-size: 28px;
  font-weight: 600;
  color: #2d426a;
  white-space: nowrap;
}

@media (max-height: 750px) and (min-width: 48em) {
  .value {
    line-height: 32px;
  }
}
@media (max-height: 720px) and (min-width: 48em) {
  .value {
    font-size: 20px;
    line-height: 24px;
  }
}

@media (max-height: 640px) and (min-width: 48em) {
  .value {
    font-size: 12px;
    line-height: 12px;
  }
}

.delta {
  font-size: 12px;
  font-weight: 600;
  color: #2d426a;
}

@media (max-height: 700px) and (min-width: 48em) {
  .delta {
    line-height: 14px;
  }
}

.delta.positive {
  color: #40b975;
}

.delta.negative {
  color: #ff5876;
}

.plotContainer {
  width: 50%;
}
