.container {
  position: relative;
  width: 100%;
  height: 100%;
}

.container svg {
  overflow: visible;
}

.xAxis {
  color: #7b828f;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 11px;
  text-anchor: middle;
}

.xAxis text {
  fill: currentColor;
}

.gridLine {
  stroke: #e6e8ed;
  stroke-width: 1.3;
  stroke-dasharray: 1, 3;
  stroke-linecap: round;
}

.bar {
  transition: opacity 0.2s;
}

.bar {
  filter: opacity(0.8);
}

.bar.highlighted {
  filter: opacity(1);
}

.hoverIndicator {
  fill: #e6e8ed;
  pointer-events: none;
}

.chart {
  pointer-events: all;
  cursor: pointer;
}
