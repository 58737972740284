.legend {
  display: flex;
  flex-wrap: wrap;
  row-gap: 8px;
  column-gap: 12px;
  padding: 6px 0;
  font-size: 11px;
  color: #636d7e;
}

.legendItem {
  display: flex;
  align-items: center;
  gap: 4px;
  white-space: nowrap;
}

.legendSwatch {
  width: 9px;
  height: 9px;
  border-radius: 2px;
}

.legendLabel {
  line-height: 1;
}
