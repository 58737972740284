.container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.barContainer:hover .bar {
  opacity: 0.3;
}

.segment {
  transition: opacity 0.2s ease-in-out;
  stroke-width: 2px;
  stroke: transparent;
  stroke-opacity: 0;
}

.bar {
  transition: opacity 0.2s ease-in-out;
}

.bar:hover {
  opacity: 1 !important;
}

.legendContainer {
  overflow-y: auto;
  scrollbar-width: auto;
  scrollbar-color: transparent transparent;

  &::-webkit-scrollbar-track {
    background: 'transparent';
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 10px;
    border: 3px solid transparent;
  }
}

.legendContainer:hover {
  scrollbar-color: #e3e3e3 transparent;

  &::-webkit-scrollbar {
    width: 12px;
  }
  &::-webkit-scrollbar-track {
    background: 'transparent';
  }
  &::-webkit-scrollbar-thumb {
    background-color: #e6ebf4;
    border-radius: 10px;
    border: 3px solid #ffffff;
  }
}
