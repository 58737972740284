:root {
  --grid-gap: 1.5rem;
}

/* Base styles for grid items */
.gridItem {
  position: relative;
}

.container {
  display: flex;
  flex-direction: column;
  gap: var(--grid-gap);
  width: 100%;
  height: 100%;
}

.row {
  display: flex;
  gap: var(--grid-gap);
  width: 100%;
  height: calc((100% - 2 * var(--grid-gap)) / 3);
}

.rowItem {
  position: relative;
  min-width: 0;
  width: 50%;
  height: 100%;
}
.rowItem::before,
.rowItem::after {
  content: '';
  position: absolute;
  background-color: #e5ebf1;
  z-index: 1;
}

/* Horizontal line (after pseudo-element) */
.rowItem::after {
  width: calc(100% + var(--grid-gap));
  height: 1px;
  left: 0;
  top: calc(-1 * (var(--grid-gap) / 2));
}
.rowItem:nth-child(even)::after {
  width: 100%;
}

/* Vertical line (before pseudo-element) */
.rowItem::before {
  width: 1px;
  height: calc(100% + var(--grid-gap));
  top: 0;
  left: calc(-1 * (var(--grid-gap) / 2));
}
.row:last-of-type .rowItem::before {
  height: calc(100%);
}

/* Hide unnecessary lines */
.rowItem:nth-child(odd)::before,
.row:first-of-type .rowItem::after {
  display: none;
}

.infoLink {
  color: #40b975;
}
