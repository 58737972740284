.container {
  width: 100%;
  height: 100%;
}

.barLabelCenter {
  display: flex;
  align-items: center;
  height: 100%;
}

.barLabel {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 12px;
  color: #636d7e;
}

.barTooltipLabel {
  font-size: 12px;
  font-weight: 600;
}

.barTooltipValue {
  font-size: 12px;
}

.bar {
  cursor: pointer;
  transition: 200ms opacity ease-in-out;
}

.highlightOnHover:has(.bar:hover) .bar {
  opacity: 0.5;
}

.highlightOnHover:has(.bar:hover) .bar:hover {
  opacity: 1;
}
