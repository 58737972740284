.container {
  width: 100%;
  height: 100%;
}

.labelCenter {
  display: flex;
  align-items: center;
  height: 100%;
}
.yAxisLabel {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  width: 100%;
  height: 32px;
  text-align: right;
  font-size: 11px;
  color: #6b7a96;
  font-weight: 400;
}

.xAxis {
  color: #6b7a96;
  font-family: 'Source Sans Pro', Roboto, sans-serif;
  font-size: 11px;
  text-anchor: middle;
}

.xAxis text {
  fill: currentColor;
}

.xAxis path,
.xAxis line {
  display: none;
}

.axisGridLine {
  stroke: #e6e8ed;
  stroke-width: 1.3;
  stroke-dasharray: 1, 3;
  stroke-linecap: round;
}

.box {
  stroke-width: 2;
  fill-opacity: 0.2;
  cursor: pointer;
}

.boxMidLine {
  stroke-width: 2;
}

.dimmed rect,
.dimmed line {
  stroke-opacity: 0.3;
  fill-opacity: 0.1;
}
