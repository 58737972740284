.container {
  position: relative;
  width: 100%;
  height: 100%;
}

.container svg {
  overflow: visible;
}

.xAxis {
  color: #6b7a96;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 10px;
  text-anchor: middle;
}

.xAxis text {
  fill: currentColor;
}

.yAxis {
  color: #6b7a96;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 10px;
  text-anchor: middle;
}

.yAxis text {
  fill: currentColor;
}

.chart {
  cursor: pointer;
}

.line {
  stroke-width: 2;
  fill: none;
}

.area {
  stroke: none;
  stroke-width: 0;
}
