.barTooltipLabel {
  font-size: 12px;
  line-height: 2;
  margin-bottom: 6px;
  font-weight: 600;
}

.barTooltipValue {
  font-size: 11px;
  line-height: 1;
  font-weight: 400;
}

.barTooltipValueNoData {
  font-size: 11px;
  line-height: 1;
  font-weight: 400;
  text-align: center;
}

.highlight {
  font-size: 11.5px;
  font-weight: 600;
}
