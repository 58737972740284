.card {
  padding: 12px 16px;
  width: 100%;
  height: 100%;
  transition:
    width 0.3s ease-in-out,
    height 0.3s ease-in-out;
}

.cardSlim {
  padding: 8px 14px;
}

.cardHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px;
}

.cardHeaderSlim {
  padding-bottom: 0;
}

.cardBody {
  height: calc(100% - 32px);
}

.titleContainer {
  display: flex;
  align-items: center;
  max-width: 80%;
}

.title {
  font-size: 14px;
  color: #2d426a;
  font-weight: 600;
  white-space: nowrap;
}
.small .title {
  font-size: 12px;
}

.titleSlim {
  font-size: 12px;
}

.titleSlim {
  font-size: 12px;
}

.info {
  display: flex;
  align-items: center;
  margin-left: 4px;
  cursor: pointer;
  line-height: 16px;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.card:hover .info {
  opacity: 1;
}

.topRight {
  display: flex;
}

.menuButton {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.card:hover .menuButton {
  opacity: 1;
}

.menuItem {
  font-size: 14px;
}

.menuItem > :global(.chakra-menu__icon-wrapper) {
  margin-right: 0.5rem;
}
